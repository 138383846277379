import * as React from 'react'
import { Container } from '../components/Container'
import logo from '/public/images/nubrakes-logo.svg'
import Image from 'next/image'

const NotFoundPage: React.FC = () => {
  return (
    <Container className="m-4">
      <div className="text-center flex flex-col items-center justify-center flex-grow">
        <Image alt="NuBrakes Logo" width={'210'} src={logo} className="d-inline-block align-top cursor-pointer" />
        <h1>404</h1>
        <h4>Page not found</h4>
      </div>
    </Container>
  )
}

export default NotFoundPage
